import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
// import PreviewCompatibleImage from './PreviewCompatibleImage'

class UpdateRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <info>
                <header>
                  <h3> <Link className="text-primary" to={post.fields.slug} > {post.frontmatter.title} </Link> </h3>
                </header>
                <p>
                  {post.excerpt}
                </p>
                <p> <Link className="button" to={post.fields.slug}> 続きを読む → </Link> </p>
                <p className="text-right text-muted">
                  <span> 掲載日時: {post.frontmatter.published_date} </span><br />
                  <span> 最終更新日時: {post.frontmatter.modified_date} </span>
                </p>
              </info>
            </div>
          ))}
      </div>
    )
  }
}

UpdateRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query UpdateRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___published_date] }
          filter: { frontmatter: { templateKey: { eq: "update-page" }, invisible:{ne: true}, show_index: {eq: true} } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                published_date(formatString: "YYYY/MM/DD HH:mm")
                modified_date(formatString: "YYYY/MM/DD HH:mm")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <UpdateRoll data={data} count={count} />}
  />
)
